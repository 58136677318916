<template>
  <Box customClass="issue-item">
    <Columns isMobile isMultiline customClass="">
      <Column customClass="is-1">
        <p class="">#{{ issue.iid }}</p>
      </Column>

      <Column customClass="">
        <h3 class="has-text-weight-semibold has-text-dark title is-5 is-clickable" @click.prevent="$emit('clickIssueHandle')">
          {{ issue.title }}
        </h3>

        <project-name v-if="issue.project_id" :id="issue.project_id" />

        <a :href="issue.web_url? issue.web_url : issue.webUrl ? issue.webUrl : '#' " title="" class="is-size-7" target="_blank">View on Gitlab</a>
      </Column>

      <Column customClass="is-half-mobile is-3">

        <div v-if="issue.labels" class="tags">

          <div v-if="issue.labels.hasOwnProperty('nodes')">
            <span v-for="(label, index) in issue.labels.nodes"
                  :key="index"
                  class="tag has-text-weight-semibold has-text-white"
                  :style="{ 'background-color': label.color }"
            >
                  {{ label.title }}
            </span>
          </div>
          <div v-else>
             <span v-for="(label, index) in issue.labels"
                  :key="index"
                  class="tag has-text-weight-semibold has-text-white"
                  :style="{ 'background-color': label.color }"
            >
                  {{ label.name }}
            </span>
          </div>






        </div>
      </Column>
      <Column v-if="time_spent" customClass="is-half-mobile is-2">
        <Box  className="is-shadowless">
          <p class="is-size-5 has-text-weight-semibold has-text-link">{{ time_spent }}</p>
        </Box>
      </Column>
      <Column customClass="is-1">
        <Box title="Issue Status" className="is-shadowless">
          <span
            class="tag has-text-weight-bold "
            :class="[ issue.state === 'opened' ? 'is-light' : 'is-success' ]"
          >
            {{ issue.state ? issue.state : 'N/A' }}
          </span>
        </Box>
      </Column>

      <slot name="issue_action"></slot>
    </Columns>
  </Box>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import useConvertDuration from '@/use/useConvertDuration'

export default {
  name: 'issue',
  props:{
    issue: {
      type: Object,
      required: true,
    }
  },
  components: {
    Columns: defineAsyncComponent(() => import('@/components/ds/Columns.vue')),
    Column: defineAsyncComponent(() => import('@/components/ds/Column.vue')),
    Box: defineAsyncComponent(() => import('@/components/ds/Box.vue')),
    ProjectName: defineAsyncComponent(() => import('@/components/widgets/taskProgress/projectName')),
  },
  setup(props) {
    const time_spent = useConvertDuration(props.issue.totalTimeSpent)
    return {
      time_spent
    }
  }
}
</script>

<style lang="scss" scoped>
  .issue-item {
    border-bottom: 1px solid #e8e8e8;
    border-top: 1px solid transparent;
    background: #fff;
    border-radius: 0 !important;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0), 0 0px 0 1px rgba(10, 10, 10, 0);
    /* transition: all 500ms ease; */
    margin-bottom: 0 !important;

    /* @media screen and (min-width: 992px) {
      padding-top: 1.25rem;
      margin-bottom: 1em !important;
      padding-bottom: 0.5em;
    } */

    &:hover {
      /* box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02); */
      background: hsl(0, 0%, 96%);
    }

    .title {
      line-height: 1.5;
      margin-bottom: 0.25rem;
    }
  }
</style>